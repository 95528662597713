@import '../../node_modules/swiper/swiper';
@import '~bootstrap/scss/bootstrap-reboot.scss';
@import '~bootstrap/scss/bootstrap-grid.scss';
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/images';
@import '~bootstrap/scss/utilities/text';
@import '~bootstrap/scss/utilities/borders';
//@import '~bootstrap/scss/utilities/display';

/* base */
@import 'base/fonts';
@import 'base/variables';
@import 'base/mixins';
@import 'base/extends';
@import 'base/common';

/* mixes */
@import 'base/bg';
@import 'base/img';
@import 'base/text';
@import 'base/color';
@import 'base/border';
@import 'base/width';
@import 'base/height';
@import 'base/mixes';

/* tpl */
@import 'tpl/btn';
@import 'tpl/map';
@import 'tpl/nav';
@import 'tpl/tabs';
@import 'tpl/page';
@import 'tpl/logo';
@import 'tpl/form';
@import 'tpl/swiper';
@import 'tpl/toggler';
@import 'tpl/quiz';
@import 'tpl/price';
@import 'tpl/promo';
@import 'tpl/header';
@import 'tpl/footer';
@import 'tpl/wrapper';
@import 'tpl/section';
@import 'tpl/pathway';
@import 'tpl/content';
@import 'tpl/project';
@import 'tpl/reviews';
@import 'tpl/feature';
@import 'tpl/contacts';
@import 'tpl/partners';
@import 'tpl/blockquote';
@import 'tpl/gallery-grid';
@import 'tpl/input-number';
