.zi-1 {
  @extend %zi-1;
}

.zi-2 {
  @extend %zi-2;
}

.pos-rel {
  position: relative;
}

.pos-stat {
  position: static;
}

.overflow-hidden {
  overflow: hidden;
}

.pointer-events-none {
  pointer-events: none;
}

.list-counter {
  counter-reset: section;
}

.box-shadow {
  box-shadow: 0 0 40px 0 rgba(0,0,0,.2);
}
