.contacts {
  &.contacts_border_bottom {
    @include border(0 0 1px,#fff);
  }
}

.contacts__item {
  @extend %d-flex-ai-center;
  margin: 0 0 24px;
}

.contacts__icon {
  @include media-breakpoint-up(md) {
    @include fw(32px);
    @include wh(32px);
    margin: 0 30px 0 0;
  }

  @include media-breakpoint-down(sm) {
    @include fw(24px);
    @include wh(24px);
    margin: 0 24px 0 0;
  }
}

.contacts__title {
  @include media-breakpoint-up(md) {
    @include fs-lh(22px,1.2);
  }

  @include media-breakpoint-down(sm) {
    @include fs-lh(18px,$line-height__content);
  }
}

.contacts__subtitle {
  @include fs-lh(14px,1.3);
  color: #666;
}
