.form__item {
  position: relative;
}

.form__title {
  margin-bottom: 0;
}

.form__field {
  @include wh(100%,36px);
  padding: 4px 12px;
  border-radius: 4px;
  border: 1px solid $color__border;
  background-color: #fff;

  &.form__field_type_textarea {
    resize: none;
    min-height: 108px;
  }

  &.form__field_bg_brand {
    color: #fff;
    border-color: #fff;
    background-color: $color__brand;
  }

  &.form__field_active {
    color: $color__default;
    border-color: $color__light;
    background-color: #fff;
  }

  &:focus {
    @extend .form__field_active;
  }
}

.form__error {
  @include pos(100%,0);
  width: 100%;
  margin: 4px 0 0;
  font-size: 12px;
  text-align: left;
  color: $color__danger;
}

.form__icon {
  @include fw(32px);
  @include wh(32px);
  color: #a10619;
}

.form__uploader {
  @extend %toggler-default;

  & + .form__title {
    @extend %d-flex-ai-center;
    cursor: pointer;
    font-weight: 700;
    text-decoration: underline;
  }
}

.form__uploader-meta {
  display: none;
  color: lighten($color__brand,25%);
}

.form__uploader-filename {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.form__uploader-reset {
  @extend %btn-default;
  @extend %d-flex-ai-center;
  color: #a10619;
}

.form__pane {
  display: none;

  &.form__pane_active {
    display: block;
  }
}

.form__expander {
  @extend %btn-default;
  @include border(0 0 2px,#fff,dashed);
  padding: 0 0 2px;

  &.form__expander_active {
    .form__expander-show {
      display: none;
    }

    .form__expander-hide {
      display: block;
    }
  }
}

.form__expander-hide {
  display: none;
}
