.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.h-100vh {
  height: 100vh !important;
}
