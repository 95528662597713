.bg-none {
  background-color: rgba(0,0,0,0) !important;
}

.bg-brand {
  background-color: $color__brand !important;
}

.bg-info {
  background-color: $color__info !important;
}

.bg-success {
  background-color: $color__success !important;
}

.bg-warning {
  background-color: $color__warning !important;
}

.bg-danger {
  background-color: $color__danger !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-light {
  background-color: $color__light !important;
}

.bg-lighten {
  background-color: $color__lighten !important;
}

.bg-disabled {
  background-color: $color__disabled !important;
}

.bg-dark {
  background-color: $color__dark !important;
}
