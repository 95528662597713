.border-brand {
  border-color: $color__brand !important;
}

.border-info {
  border-color: $color__info !important;
}

.border-success {
  border-color: $color__success !important;
}

.border-warning {
  border-color: $color__warning !important;
}

.border-danger {
  border-color: $color__danger !important;
}

.border-white {
  border-color: #fff !important;
}

.border-light {
  border-color: $color__light !important;
}

.border-lighten {
  border-color: $color__lighten !important;
}

.border-disabled {
  border-color: $color__disabled !important;
}

.border-dark {
  border-color: $color__dark !important;
}
