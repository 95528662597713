@mixin before {
  position: relative;

  &:before {
    content: '';
    position: absolute;
  }
}

@mixin mw($mw, $width: 100%) {
  width: $width;
  max-width: $mw;
}

@mixin wh($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin fw($fw) {
  flex: 0 0 $fw;
  max-width: $fw;
}

@mixin offset($margin, $padding) {
  margin: $margin;
  padding: $padding;
}

@mixin px($px__l, $px__r: $px__l) {
  padding: {
    left: $px__l;
    right: $px__r;
  }
}

@mixin py($py__t, $py__b: $py__t) {
  padding: {
    top: $py__t;
    bottom: $py__b;
  }
}

@mixin mx($mx__l, $mx__r: $mx__l) {
  margin: {
    left: $mx__l;
    right: $mx__r;
  }
}

@mixin my($mx__t, $mx__b: $mx__t) {
  margin: {
    left: $mx__t;
    right: $mx__b;
  }
}

@mixin pos($pt, $pl, $pp: absolute) {
  top: $pt;
  left: $pl;
  position: $pp;
}

@mixin border($bw, $bc, $bs: solid) {
  border: {
    width: $bw;
    color: $bc;
    style: $bs;
  }
}

@mixin color($color) {
  color: $color;

  &:hover,
  &:focus {
    color: $color;
  }
}

@mixin bg($bgs, $bgp: 0 0) {
  background: {
    size: $bgs;
    position: $bgp;
    repeat: no-repeat;
    color: rgba(0, 0, 0, 0);
  }
}

@mixin font($fs, $fw) {
  font: {
    size: $fs;
    weight: $fw;
  }
}

@mixin fs-lh($fs, $lh) {
  font-size: $fs;
  line-height: $lh;
}

@mixin h1 {
  font-weight: 700;

  @include media-breakpoint-up(md) {
    font-size: 36px;
  }

  @include media-breakpoint-down(sm) {
    font-size: 32px;
  }
}

@mixin h2 {
  @include media-breakpoint-up(md) {
    font-size: 30px;
  }

  @include media-breakpoint-down(sm) {
    font-size: 26px;
  }
}

@mixin h3 {
  @include media-breakpoint-up(md) {
    font-size: 24px;
  }

  @include media-breakpoint-down(sm) {
    font-size: 18px;
  }
}

@mixin h4 {
  @include media-breakpoint-up(md) {
    font-size: 18px;
  }

  @include media-breakpoint-down(sm) {
    font-size: 16px;
  }
}

@mixin h5 {
  font-size: $font-size__default;
}

@mixin h6 {
  font-size: 14px;
}

@mixin inline-block($va: top) {
  display: inline-block;
  vertical-align: $va;
}
