%wrapper {
  @include mx(auto);
}

%d-flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

%d-flex-nowrap {
  display: flex;
  flex-wrap: nowrap;
}

%d-flex-ai-center {
  display: flex;
  align-items: center;
}

%d-flex-column {
  display: flex;
  flex-direction: column;
}

%d-flex-centered {
  @extend %d-flex-ai-center;
  justify-content: center;
}

%btn-default {
  //@include inline-block;
  border: 0;
  outline: 0;
  padding: 0;
  cursor: pointer;
  background: none;
}

%toggler-default {
  @include wh(0);
  @include pos(0,0);
  opacity: 0;
  overflow: hidden;
  visibility: hidden;
  pointer-events: none;
}

%link-default {
  text-decoration: none;
  color: $color__link;

  &:hover,
  &:focus {
    text-decoration: none;
    color: $color__link;
  }
}

%text-default {
  @include fs-lh($font-size__default, $line-height__content);
}

%zi-1 {
  z-index: 1;
  position: relative;
}

%zi-2 {
  z-index: 2;
  position: relative;
}
