*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  font: $font-size__default $font-family__default;
  line-height: $line-height__default;
  color: $color__default;
}

p {
  @extend %text-default;
}

a {
  @extend %link-default;
}

ol,
ul {
  padding-left: 0;
  margin-bottom: 0;

  li {
    list-style: none;
  }
}

textarea,
input {
  outline: 0;
  outline: none;
  box-shadow: none;
}

button {
  outline: 0;
  cursor: pointer;

  &:hover,
  &:focus {
    outline: 0;
    cursor: pointer;
  }
}
